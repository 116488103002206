var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c(
            "b-form-group",
            {
              staticClass: "mb-2",
              attrs: { "label-cols-md": "3", label: _vm.$t("DEFAULT_VALUE") },
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.loaLevels },
                model: {
                  value: _vm.default_loa_level,
                  callback: function ($$v) {
                    _vm.default_loa_level = $$v
                  },
                  expression: "default_loa_level",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols-md": "3",
                label: _vm.$t("LOA.MESSAGE_LOA_ENABLED"),
                "label-class": "font-weight-bold",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-2",
                  attrs: { "label-cols-md": "3", label: _vm.$t("LOA.LEVEL.1") },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      name: "check-button",
                      value: 1,
                      "unchecked-value": 0,
                    },
                    model: {
                      value: _vm.loa1_enabled,
                      callback: function ($$v) {
                        _vm.loa1_enabled = $$v
                      },
                      expression: "loa1_enabled",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-2",
                  attrs: { "label-cols-md": "3", label: _vm.$t("LOA.LEVEL.2") },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      name: "check-button",
                      value: 1,
                      "unchecked-value": 0,
                    },
                    model: {
                      value: _vm.loa2_enabled,
                      callback: function ($$v) {
                        _vm.loa2_enabled = $$v
                      },
                      expression: "loa2_enabled",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-2",
                  attrs: { "label-cols-md": "3", label: _vm.$t("LOA.LEVEL.3") },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      name: "check-button",
                      value: 1,
                      "unchecked-value": 0,
                    },
                    model: {
                      value: _vm.loa3_enabled,
                      callback: function ($$v) {
                        _vm.loa3_enabled = $$v
                      },
                      expression: "loa3_enabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols-md": "3",
                label: _vm.$t("LOA.CHANGE_TEXT.TITLE"),
                "label-class": "font-weight-bold",
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: { "label-cols-md": "3", label: _vm.$t("LOA.LEVEL.1") },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      placeholder: _vm.$t("LOA.1"),
                      name: "editLoa1Text",
                    },
                    model: {
                      value: _vm.loa1_text,
                      callback: function ($$v) {
                        _vm.loa1_text = $$v
                      },
                      expression: "loa1_text",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: { "label-cols-md": "3", label: _vm.$t("LOA.LEVEL.2") },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      placeholder: _vm.$t("LOA.2"),
                      name: "editLoa2Text",
                    },
                    model: {
                      value: _vm.loa2_text,
                      callback: function ($$v) {
                        _vm.loa2_text = $$v
                      },
                      expression: "loa2_text",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: { "label-cols-md": "3", label: _vm.$t("LOA.LEVEL.3") },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      placeholder: _vm.$t("LOA.3"),
                      name: "editLoa3Text",
                    },
                    model: {
                      value: _vm.loa3_text,
                      callback: function ($$v) {
                        _vm.loa3_text = $$v
                      },
                      expression: "loa3_text",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary" },
              on: { click: _vm.editBtn },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
          _vm.user.information.organisation.id != _vm.information.id
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill float-right",
                  attrs: { variant: "danger" },
                  on: { click: _vm.removeBtn },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }